import { all, takeLatest } from "redux-saga/effects";
import { types as modalsAnchorActions } from "@/bit/reducers/reducers.modals-reducer";
import { types as headerActions } from "@/bit/reducers/reducers.header-reducer";
import { types as mainActions } from "@/bit/reducers/reducers.main-reducer";
import {
  initAnchorModalListener,
  addModal,
} from "@/bit/sagas/sagas.modals-saga";
import {
  getSearcherInfo,
  getRelatedPages,
} from "@/bit/sagas/sagas.header-saga";
import {
  checkCPAndSetZones,
  setReadNotificationsSaga,
} from "@/bit/sagas/sagas.main-saga";

function* rootSaga() {
  yield all([
    takeLatest(
      modalsAnchorActions.INIT_ANCHOR_MODAL_LISTENER,
      initAnchorModalListener
    ),
    takeLatest(modalsAnchorActions.ADD_MODAL, addModal),
    //searcher
    takeLatest(headerActions.GET_SEARCHER_INFO, getSearcherInfo),
    takeLatest(headerActions.GET_RELATED_PAGES, getRelatedPages),
    //cp
    takeLatest(mainActions.CHECK_CP_AND_SET_ZONES, checkCPAndSetZones),
    takeLatest(
      mainActions.SET_READ_NOTIFICATIONS_INIT,
      setReadNotificationsSaga
    ),
  ]);
}

export default rootSaga;
